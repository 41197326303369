import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Slider from './Slider';
import styles from './AccountEnrollStyles.js';
import AccountEnrollForm from './AccountEnrollForm';
import { useMoralis } from "react-moralis";
import useSaveUserData from './UserRecords/UserData';





const AccountEnroll = (props) => {
    const [formErrors, setFormErrors] = useState({})
    const [inputs, setInputs] = useState({
        first_name: '',
        last_name: '',
        dob: '',
        phone_number: '',
        ssn: '',
        street1: "",
        zipcode: "",
        email: "",
        kyc_type: "",
        tos_timestamp: ""
    })

    const { first_name, last_name, dob, phone_number, ssn, street1, zipcode, email } = inputs;
    const [acctTokenForDB, setAccountToken] = useState('');
    const { account } = useMoralis();
    const setUserRecordData = useSaveUserData();

    useEffect(() => {
        if (acctTokenForDB && account) {
            setUserRecordData(acctTokenForDB, first_name, last_name, email, phone_number)
        }
    }, [acctTokenForDB])



    const handleDateChange = (e) => {
        var val = e.target.value;
        setInputs({
            ...inputs,
            dob: val
        })

    }

    const changeHandler = (e) => {
        let newValue = e.target.value;
        setInputs({
            ...inputs,
            [e.target.name]: newValue
        })
    }

    const ValidateForm = () => {
        const { dob, zipcode, ssn, phone_number } = inputs
        let isValid = true;
        const errors = {};

        // const emailRreg = /^w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        // if (!email || emailRreg.test(this.state.email) === false) {
        //     errors.inValidemail = 'Email Field is Invalid';
        //     isValid = false;

        // }
        const numOnly = /^[0-9\b]+$/;
        if (!ssn || ssn.length != 9 || numOnly.test(ssn) === false) {
            errors.inValidssn = 'Invalid SSN. Please enter valid 9 digits';
            isValid = false;//

        }

        // const numOnlyReg = /^[0-9]*$/;
        if (!phone_number || phone_number?.length !== 10 || numOnly.test(phone_number) === false) {
            errors.invalidPhone = 'Phone number is Invalid. Please enter valid 10 digits US Phone number';
            isValid = false;

        }

        if (!zipcode || zipcode?.length < 5 || numOnly.test(zipcode) === false) {
            errors.invalidZip = 'Zip Code is Invalid';
            isValid = false;

        }

        if (!dob) {
            errors.invalidDob = 'Date of Birth is Invalid';
            isValid = false;
        } else {

            var m = moment(dob, 'YYYY-MM-DD')
            if (!m.isValid()) {
                errors.invalidDobFormat = 'Date of Birth is not in a valid format';
                isValid = false;
            }
        }

        setFormErrors(errors);
        return isValid;

    }

    const handleAccountCreation = e => {
        //TO DO: Write logic to validate and post to API
        e.preventDefault();
        const isFormValid = ValidateForm();

        if (isFormValid) {
            const accountenrollUrl = "api1/postEnroll"
            console.log('Enroll Url ' + accountenrollUrl)
            var formattedTos = moment(new Date()).toISOString();

            var raw = JSON.stringify(
                {
                    "first_name": first_name,
                    "last_name": last_name,
                    "dob": dob,
                    "phone_number": phone_number,
                    "ssn": ssn,
                    "street1": street1,
                    "zipcode": zipcode,
                    "email": email,
                    "kyc_type": "BASIC",
                    "tos_timestamp": formattedTos
                });


            var requestOptions = {
                method: 'POST',
                body: raw,
                redirect: 'follow'
            };

            fetch(accountenrollUrl, requestOptions)
                .then(response => {
                    if (!response.ok) {
                        setFormErrors({
                            ...formErrors,
                            default: "Please check the form and enter details correctly, please note we only accept US-Residents at this time. "
                        })
                        throw Error('Technical difficulties processing the action.')
                    }
                    return response;
                })
                .then(response => response.text())
                .then(result => {
                    var details = JSON.parse(result).data;

                    if (Boolean(details.kyc.success) &&
                        details.result === "ACCEPTED" &&
                        (details.account_token !== undefined && details.account_token !== '' && details.account_token !== null)) {
                        setAccountToken(details.account_token)
                        props.updateToken(details.account_token)
                    }

                })
                .catch(error => {
                    setFormErrors({
                        ...formErrors,
                        default: "Please check the form and enter details correctly, please note we only accept US-Residents at this time. "
                    })
                });
        }


    }

    return (

        <div style={styles.card}>
            <h1 className="generate-card">Generate Card</h1>
            <Slider firstPage={true}></Slider>
            <AccountEnrollForm
                inputs={inputs}
                changeHandler={changeHandler}
                handleAccountCreation={handleAccountCreation}
                handleDateChange={handleDateChange}
                errors={formErrors}
            />
        </div>

    )


}

export default AccountEnroll;


// const useFetch = (url, options) => {
//     const [data, setData] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     useEffect( async () => {
//         fetch(url, options)
//         .then(res => res.json())
//         .then(data => {
//             setLoading(false);
//             setData(data);
//         })
//         .catch(err => {
//             setLoading(false);
//             setError(err);
//         })
//     }, [url])

//     return {data, loading, error}
// }