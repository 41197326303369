import { EditOutlined } from "@ant-design/icons";
import { Box, Button, Modal, Typography } from "@mui/material";
// import { render } from "@testing-library/react";
import React, { useState } from "react";
import "./cards.css";
import EmbedCard from "./EmbedCard";
import Transactions from './Transactions';
import UpdateCard from './UpdateCard';
import AddCard from './AddCard';



const Card = (props) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        maxWidth: "100%",
        // bgcolor: 'rgba(255,255,255,0.5)',
        border: '2px solid #000',
        boxShadow: 24,
        borderRadius: 4,
        p: 4,
        // backgroundImage: "url(/aurora-bg.png)"
        //backgroundImage: "url(/jw-web-flowing5.jpg)"
    };
    const cardsFiltered = props.cardData?.sort((a, b) => new Date(b.created) - new Date(a.created))
        ;
    const [cardTkn, setCardToken] = useState(cardsFiltered[0].token);
    // const [cardTkn, setCardToken] = useState();
    const [openUpdateCard, setOpenUpdateCard] = useState(false);
    const [openNewCard, setOpenNewCard] = useState(false);

    const [cardItem, setCardItem] = useState(cardsFiltered[0]);

    const [showBackCard, setFlipSide] = useState({});

    const handleOpenUpdateCard = () => setOpenUpdateCard(true);
    const handleCloseUpdateCard = () => setOpenUpdateCard(false);

    const handleOpenNewCard = () => {
        // console.log(props.acctToken)

        if (props.acctToken !== "12345678910") {
            setOpenNewCard(true);
        }
    }
    const handleCloseNewCard = () => setOpenNewCard(false);

    const toggleCardFlip = (token) => {
        let temp = { ...showBackCard };
        if (temp[token]) {
            temp[token] = false;
        } else {
            temp[token] = true;
        }
        setFlipSide(temp);
        // console.log(showBackCard)
        // console.log(cardItem)
    }


    function handleCardClick(cardTkn, acctToken, cardItem) {
        // console.log('Card Click values : ' + cardTkn);
        // console.log('Account Token : ' + acctToken);
        // console.log('Card Item details : ' + cardItem.token);

        setCardToken(cardTkn);
        setCardItem(cardItem);


    }



    return (

        <React.Fragment>
            <div className="card-list-left">
                <div className="card-title-container">
                    <Typography variant="h6">Cards</Typography>
                    {<Button style={{
                    }} type='submit'
                        onClick={() => handleOpenNewCard()}>
                        {/* <span>New Card</span> */}
                        <a><img src="./create-new-card.svg" variant="outlined" onClick={() => handleOpenNewCard()}></img></a></Button>}
                </div>
                <div className="contain-all-cards">
                    {
                        cardsFiltered.map((card, index) =>
                        (
                            <div key={card.token} className={"card-container-iframe " + ((card.token === cardTkn) ? 'selected' : '')} onClick={() => handleCardClick(card.token, props.acctToken, card)} id={index}>
                                <div className="flip-card" title="Click to Copy" onClick={() => toggleCardFlip(card.token)}>
                                    <div className={"flip-card-inner flipping" + ((showBackCard[card.token]) ? 'flipping' : '')}>
                                        <div className={"card flip-card-front"} title="Click to Copy">
                                            <div className='card__top' title="Click to Copy">
                                                <div>{card.state}</div>
                                                <div>${card.spend_limit / 100}</div>
                                                <div className="edit-card">
                                                    <EditOutlined onClick={() => handleOpenUpdateCard()} />
                                                </div>
                                            </div>
                                            <div className='card__bottom'>
                                                <div>{card.memo} </div>
                                                <div>{card.last_four}</div>
                                            </div>
                                        </div>
                                        <EmbedCard cardTkn={card.token} acctToken={props.acctToken}></EmbedCard>
                                    </div>
                                </div>
                            </div>

                        ))

                    }
                </div>
            </div>

            <div className="transactions-container">
                <Transactions cardTkn={cardTkn} acctToken={props.acctToken} selectedCard={cardItem}></Transactions>
            </div>


            {
                cardTkn && props.acctToken &&
                <>
                    <Modal
                        open={openNewCard}
                        onClose={handleCloseNewCard}
                        aria-labelledby="New Card"
                        aria-describedby="New Card"
                        style={{ zIndex: 20 }}
                    >

                        <Box sx={style}>
                            <AddCard acctToken={props.acctToken} updateCard={props.updateCard} closeNewCard={handleCloseNewCard}></AddCard>
                        </Box>

                    </Modal>

                    <Modal
                        open={openUpdateCard}
                        onClose={handleCloseUpdateCard}
                        aria-labelledby="Update Card"
                        aria-describedby="Update Card"
                    >

                        <Box sx={style}>
                            <UpdateCard cardItem={cardItem} acctToken={props.acctToken} updateCard={props.updateCard} closeUpdateCard={handleCloseUpdateCard}></UpdateCard>
                        </Box>

                    </Modal>
                </>
            }


        </React.Fragment>

    );
};
export default Card;

