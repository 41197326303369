import React, { useEffect, useState } from 'react';
import AddCard from './AddCard';
import AccountEnroll from './AccountEnrollfn';
import "./cards.css";
import Card from './Card';
import { useMoralis } from "react-moralis";
import Account from "components/Account/Account";
import Loader from './Loader';
import StaticCard from './StaticCard';
import useGetUserData from './UserRecords/GetUserData';

const CardsListfn = () => {
    const [items, setItems] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const userAccountToken = useGetUserData();
    // let accounToken = '58a843c9-81c7-4cbe-83c4-a384d5ced062'; //e124dba9-7885-4988-8735-0bfccf01e05f
    const [accounToken, setAccountToken] = useState("");
    const { account, isWeb3Enabled, isAuthenticated, enableWeb3, isWeb3EnableLoading } = useMoralis();
    const [auth, setAuth] = useState({
        isAuthenticated: false,
        isisWeb3Enabled: false
    });

    const [addCardToggle, setaddCardToggle] = useState(false);

    // const [isAuthModalVisible, setIsAuthModalVisible] = useState(false);

    useEffect(() => {
        setAuth({
            isAuthenticated: isAuthenticated,
            isisWeb3Enabled: isWeb3Enabled
        })

        if (userAccountToken) {
            setAccountToken(userAccountToken)
        }
        // else {
        //     var acctTokenFromstorage = window.localStorage.getItem("accountToken");
        //     setAccountToken(acctTokenFromstorage);
        // }

        // console.log('Cards list account : ' + accounToken);

        const connectorId = window.localStorage.getItem("connectorId");
        if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading)
            enableWeb3({ provider: connectorId });
        if (isAuthenticated) { getCards() };
    }, [accounToken, isAuthenticated, userAccountToken]);


    async function getCards() {
        if (accounToken != null && accounToken !== undefined && accounToken !== '') {

            try {
                var response = await fetch("api1/getCards?accountToken=" + accounToken)
                if (response.ok) {
                    const lstCards = await response.json();
                    setItems(lstCards)
                    setIsLoaded(true);
                }
                else {
                    console.log('Error fetching account token ' + response.statusText);
                    setItems(null)
                    setIsLoaded(false);
                    throw Error('Technical difficulties processing the action.')
                }
            }
            catch (err) {
                console.log('Error fetching account token ' + err.statusText);
                setItems(null)
                setIsLoaded(true);
                throw Error('Technical difficulties processing the action.')
            }
        }
    }

    const refreshCards = () => {
        console.log('Refresh Cards called');
        getCards();
        setaddCardToggle(false)
    }

    const updateAccountTokenToParent = (acctToken) => {
        console.log('Token back to parent ' + acctToken);
        // window.localStorage.setItem('accountToken', acctToken);
        setAccountToken(acctToken)
    }

    const fakeCardData = [
        {
            token: "58a843c9-81c7-4cbe-83c4-a384d5ced061",
            spend_limit: "10000",
            memo: "FIAT BLOCKCHAIN",
            state: "OPEN",
            pan: "12345676767",
            created: "Mon Feb 28 2022 22:55:18"
        },
        {
            token: "58a8433c9-81c7-4cbe-83c4-a384d5ced062",
            spend_limit: "10002",
            memo: "ETHEREUM",
            state: "OPEN",
            pan: "5676767",
            created: "Mon Feb 28 2022 22:55:18"

        },
        {
            token: "58a8433c9-81c7-4cbe-83c4-a384d5ced063",
            spend_limit: "9000",
            memo: "POLYGON MATIC",
            state: "OPEN",
            pan: "5676767",
            created: "Mon Feb 28 2022 22:55:18"

        }
    ]


    if (isAuthenticated) {
        if (!accounToken) {
            return (
                <div>
                    <AccountEnroll updateToken={updateAccountTokenToParent} />
                    {/* <AddCard acctToken="e124dba9-7885-4988-8735-0bfccf01e05f" updateCard={refreshCards}></AddCard> */}
                </div>
            )
        }
        else {
            if (!isLoaded) {
                return (
                    <Loader setOpen={true} />
                )
            } else {
                return (
                    <div >
                        <h1 className="generate-card">My Cards</h1>
                        {(items && items.data.length) > 0 ?
                            <div className='cardslist'>
                                <ul className="cards-list-container">
                                    {
                                        <Card cardData={items.data} acctToken={accounToken} updateCard={refreshCards}></Card>
                                    }
                                </ul>
                            </div>
                            :
                            <AddCard acctToken={accounToken} updateCard={refreshCards}></AddCard>
                        }
                    </div>
                );
            }

        }

    }
    else {
        return (
            <div>

                <div className='cardslist'>
                    <ul className="cards-list-container">
                        {
                            <StaticCard cardData={fakeCardData} acctToken={"12345678910"}></StaticCard>

                        }
                    </ul>
                </div>
                <div style={{ display: "flex", justifyContent: "center", margin: "auto", padding: "20px" }}>
                    <div style={{ height: "42px", padding: "0px 15px", display: "flex", backgroundColor: "rgb(244, 244, 244)", borderRadius: "12px", width: "fit-content", cursor: "pointer", margin: "auto" }}>
                        <Account></Account>
                    </div>
                </div>
            </div>
        )

    }
}
// }






export default CardsListfn;