import { useMoralis } from "react-moralis";
import { getEllipsisTxt } from "helpers/formatters";
import Blockie from "../Blockie";
import { Button, Card } from "antd";
import { Modal } from "@mui/material";
import { useState } from "react";
import Address from "../Address/Address";
import { SelectOutlined } from "@ant-design/icons";
import { getExplorer } from "helpers/networks";
import Text from "antd/lib/typography/Text";
import { connectors } from "./config";
const styles = {
  account: {
    height: "42px",
    padding: "0 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    borderRadius: "12px",
    backgroundColor: "rgb(244, 244, 244)",
    cursor: "pointer",
    margin: "auto",
    color: "black",
  },
  text: {
    color: "magenta",
    cursor: "pointer",
  },
  connector: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "auto",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "20px 5px",
    cursor: "pointer",
    color: "black",
  },
  icon: {
    alignSelf: "center",
    fill: "rgb(40, 13, 95)",
    flexShrink: "0",
    marginBottom: "8px",
    height: "30px",
  },
};

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  backgroundColor: "white",
  border: "2px solid #000",
  borderRadius: "8px",
  padding: "4px",
  color: "black!important",
};

function Account() {
  const { authenticate, isAuthenticated, account, chainId, logout } =
    useMoralis();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAuthModalVisible, setIsAuthModalVisible] = useState(false);

  if (!isAuthenticated || !account) {
    return (
      <>
        <div onClick={() => setIsAuthModalVisible(true)} style={{ paddingTop: "10px" }}>
          <p style={styles.text}>CONNECT WALLET</p>
        </div>
        <Modal
          open={isAuthModalVisible}
          footer={null}
          onClose={() => setIsAuthModalVisible(false)}
        >
          <div style={styleModal}>
            <div
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "center",
                fontWeight: "700",
                fontSize: "20px",
                color: "black"
              }}
            >
              Connect Wallet
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
              {connectors.map(({ title, icon, connectorId }, key) => (
                <div
                  style={styles.connector}
                  key={key}
                  onClick={async () => {
                    try {
                      await authenticate({ provider: connectorId, signingMessage: "Web3 Debit Card Powered By Just Wallet" });
                      window.localStorage.setItem("connectorId", connectorId);
                      setIsAuthModalVisible(false);
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                >
                  <img src={icon} alt={title} style={styles.icon} />
                  {/* <Text style={{ color: "black!important" }}>{title}</Text> */}
                  <div style={{ color: "black!important" }}>{title}</div>
                </div>
              ))}
            </div>
          </div>
        </Modal>
      </>
    );
  }

  return (
    <>
      {/* <button
        onClick={async () => {
          try {
            console.log("change")
            await web3._provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: "0x38" }],
            });
            console.log("changed")
          } catch (e) {
            console.error(e);
          }
        }}
      >
      </button> */}
      <div style={styles.account} onClick={() => setIsModalVisible(true)}>
        <p style={{ marginRight: "5px", ...styles.text }}>
          {getEllipsisTxt(account, 6)}
        </p>
        <Blockie currentWallet scale={3} />
      </div>
      <Modal classname="disconnectwalletmodal"
        open={isModalVisible}
        footer={null}
        onClose={() => setIsModalVisible(false)}
      >
        <div style={styleModal}>
          <span style={{ color: 'black', fontSize: "1.5rem", padding: "13px" }}>Connected Wallet Account</span>
          <Card
            style={{
              marginTop: "10px",
              borderRadius: "1rem",
            }}
            bodyStyle={{ padding: "15px" }}
          >
            <Address
              avatar="left"
              size={6}
              copyable
              style={{ fontSize: "20px", backgroundColor: 'black', padding: "10px" }}
            />
            <div style={{ marginTop: "10px", padding: "0 10px" }}>
              <a style={{ textDecoration: 'none', color: 'black', a: "magenta", active: { color: 'magenta' } }}
                href={`${getExplorer(chainId)}/address/${account}`}
                target="_blank"
                rel="noreferrer">
                <SelectOutlined style={{ marginRight: "5px" }} />
                View on Explorer
              </a>
            </div>
          </Card>
          <Button
            size="large"
            type="primary"
            style={{
              width: "100%",
              marginTop: "10px",
              borderRadius: "0.5rem",
              fontSize: "16px",
              fontWeight: "500",
            }}
            onClick={async () => {
              await logout();
              window.localStorage.removeItem("connectorId");
              setIsModalVisible(false);
            }}
          >
            Disconnect Wallet
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default Account;
