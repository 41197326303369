
import { useState, useEffect } from "react";
import { useMoralis } from "react-moralis";

const useSaveUserData = (firstName, lastName, email, contactNumber) => {
    const { account, Moralis } = useMoralis();
    const [userToken, setUserToken] = useState("");

    useEffect(() => {
        // console.log('Calling Moralis DB');
        // console.log('Account and User Token ' + account + ' |||| ' + userToken);
        if (account && userToken) {
            SaveUserRecord(account, userToken, firstName, lastName, email, contactNumber)
        }

    }, [account, userToken]);

    const setUserRecordData = (userAcctToken, firstName, lastName, email, contactNumber) => {
        const newUserToken = userAcctToken;
        setUserToken(newUserToken)
        if (account && newUserToken) {
            SaveUserRecord(account, newUserToken, firstName, lastName, email, contactNumber)
        }
    }

    const SaveUserRecord = (account, userToken, firstName, lastName, email, contactNumber) => {
        const userRecord = Moralis.Object.extend('UserAccountRecord')
        const record = new userRecord();
        record.set('userWallet', account);
        record.set('userAccountToken', userToken);
        record.set('firstName', firstName);
        record.set('lastName', lastName);
        record.set('email', email);
        record.set('contactNumber', contactNumber);

        record.save();
    }

    return setUserRecordData;
}

export default useSaveUserData;