import { TextField, Button, Checkbox, FormControlLabel, Alert } from '@mui/material';
import styled from "styled-components";


const AccountEnrollForm = ({ inputs, errors, changeHandler, handleAccountCreation, handleDateChange, tandChandler }) => {
    return (
        <div className='1gencard-modal'>            <form onSubmit={handleAccountCreation}>
            {Object.keys(errors).map((key => {
                return <div>
                    <Alert severity='error'>{errors[key]}</Alert>
                    <br></br>
                </div>
            }))}
            <div className="enroll-field">
                <TextField
                    InputLabelProps={{
                        style: { color: 'white', },
                    }}
                    name="first_name"
                    type="text"
                    label="First Name"
                    placeholder='First Name'
                    defaultValue={inputs.first_name}
                    required
                    onChange={changeHandler}
                >

                </TextField>
            </div>
            <div className="enroll-field">
                <TextField
                    InputLabelProps={{
                        style: { color: 'white', },
                    }}
                    name="last_name" type="text" label="Last Name"
                    placeholder='Last Name' defaultValue={inputs.last_name} onChange={changeHandler} required>
                </TextField>
            </div>
            <div className="enroll-field">
                <TextField
                    InputLabelProps={{
                        style: { color: 'white', },
                    }}
                    name="dob"
                    label="Date of Birth"
                    type="number"
                    onFocus={(e) => e.target.type = 'date'}
                    placeholder=' '
                    defaultValue={inputs.dob}
                    onChange={handleDateChange}
                    required>
                </TextField>
            </div>
            <div className="enroll-field">
                <TextField
                    InputLabelProps={{
                        style: { color: 'white', },
                    }}
                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                    name="phone_number"
                    type="text"
                    label="Phone"
                    placeholder='1112223333'
                    defaultValue={inputs.phone_number}
                    onChange={changeHandler}
                    required
                    inputProps={{
                        maxLength: 10, minLength: 10,
                    }}
                ></TextField>
            </div>
            <div className="enroll-field">
                <TextField
                    InputLabelProps={{
                        style: { color: 'white', },
                    }}
                    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                    name="ssn"
                    type="text"
                    label="SSN"
                    placeholder='000000000'
                    defaultValue={inputs.ssn}
                    onChange={changeHandler}
                    required
                    inputProps={{
                        maxLength: 9, minLength: 9
                    }}
                >

                </TextField>
            </div>
            <div className="enroll-field">
                <TextField
                    InputLabelProps={{
                        style: { color: 'white', },
                    }}
                    name="street1" type="text" label="Street" placeholder='123 Main st' defaultValue={inputs.street1} onChange={changeHandler} required></TextField>
            </div>
            <div className="enroll-field">
                <TextField
                    InputLabelProps={{
                        style: { color: 'white', },
                    }}
                    name="zipcode" type="text" label="Zipcode" placeholder='00000' helperText='US Citizens only, and US Addresses only' defaultValue={inputs.zipcode} onChange={changeHandler} required></TextField>
            </div>
            <div className="enroll-field">
                <TextField
                    InputLabelProps={{
                        style: { color: 'white', },
                    }}
                    name="email" type="text" label="Email"
                    placeholder='abc@test.com' defaultValue={inputs.email} onChange={changeHandler} required>
                </TextField>
            </div>

            <div style={{
                width: "50%",
            }}>
                <Checkbox onChange={tandChandler} name="TandC" label="Terms"
                    required color='success'>
                </Checkbox>
                <span style={{
                    color: "white",

                    fontSize: "16px",
                }}>
                    By clicking "Create Account" you agree to; <a href='https://justwallet.com/e-sign-consent/' target="_blank">E-Sign Consent,  </a>
                </span>
                <span style={{
                    color: "white",

                    fontSize: "16px",
                }}>
                    <span></span> <a href='https://justwallet.com/privacy-policy/' target="_blank">Just Wallet Privacy Policy, </a>
                </span>
                <span>
                    <span style={{
                        color: "white",

                        fontSize: "16px",
                    }}><a href='https://justwallet.com/cardholder-agreement/' target="_blank">Cardholder agreement, </a></span>
                </span>
                <span style={{
                    color: "white",

                    fontSize: "16px",
                }}>
                    <span>and</span> <a href='https://bankpatriot.com/PatriotBank/media/Documents/Privacy_Policy.pdf' target="_blank">Patriot Bank N.A. Privacy Policies.</a>
                </span>
            </div>

            <Button
                style={{
                    border: "3px solid #e500ff",
                    color: "white",
                    fontWeight: "700",
                    fontSize: "18px",
                    lineHeight: "22px",
                    padding: "10px",
                    marginTop: "16px"
                }} variant="outlined" type='submit'>Create Account </Button>

        </form></div>
    )
}


export default AccountEnrollForm;